import React, { useState } from "react";
import axios from "axios";
import "./contact-form.scss";
import { navigate } from "gatsby";

const ContactForm = () => {
	const [fullName, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");
	const [send, setSend] = useState(false);
	const [legal, setLegal] = useState(false);

	const resetForm = () => {
		setSend(false);
		setFullName("");
		setEmail("");
		setPhone("");
		setLegal(false);
	};

	const formSubmit = (e) => {
		e.preventDefault();
		setSend(true);

		let formData = new FormData();

		formData.set("fullName", fullName);
		formData.set("email", email);
		formData.set("phone", phone);
		formData.set("message", message);
		formData.set("legal", legal);

		axios({
			method: "post",
			url: "https://wp.amazingthailand.pl/wp-json/contact-form-7/v1/contact-forms/6/feedback",
			data: formData,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(() => {
				console.log("Submit success");
				resetForm();
				navigate("/kontakt-potwierdzenie/");
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<div className="contact-form">
			<form onSubmit={formSubmit}>
				<input
					type="text"
					placeholder="Imię i Nazwisko"
					name="firstName"
					id="firstName"
					value={fullName}
					onChange={(e) => setFullName(e.target.value)}
					required
				/>
				<input
					type="email"
					placeholder="Email Address"
					name="email"
					id="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					required
				/>
				<input
					type="tel"
					placeholder="Numer telefonu"
					name="phone"
					id="phone"
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
					required
				/>
				<textarea
					name="message"
					id="message"
					placeholder="Treść wiadomości"
					value={message}
					onChange={(e) => setMessage(e.target.value)}
				/>

				<div className="contact-form__elements-container">
					<label className="checkbox-wrapper">
						<input
							name="legal"
							id="legal"
							type="checkbox"
							required
							className="contact-form__checkbox"
							onChange={(e) => setLegal(!legal)}
						/>
						<span className="checkmark"></span>
						<div className="contact-form__legal">
							* Wyrażam zgodę na przetwarzanie moich danych
							osobowych
							<a href="/polityka-prywatnosci" target="_blank">
								{" "}
								Więcej informacji
							</a>
						</div>
					</label>
					<button
						type="submit"
						className="button btn--outline btn--send"
						disabled={!legal}
					>
						{send ? "Wysyłanie..." : "Wyślij"}
					</button>
				</div>
			</form>
		</div>
	);
};

export default ContactForm;
