import "styles/pages/page-contact.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import ContactForm from "../components/ContactForm";
import Breadcrumbs from "../components/Breadcrumbs";

const ContactPage = () => {
  return (
    <Layout headerColor="#9300B9">
      <Seo title="Kontakt" />
      <SubpageHeader
        image={require("assets/images/kontakt-bg-main.jpg").default}
      />
      <Breadcrumbs currentLocationLabel="Kontakt" />
      <section className="page-contact">
        <div className="container">
          <h2 className="section-title">Skontaktuj się z nami!</h2>
          <div className="row">
            <div className="col-xl-4 offset-xl-1">
              <div className="page-contact__text">
                <p>
                  <strong>TAT - Tourism Authority of Thailand (Prague)</strong>
                </p>
                <br />
                <p>Purkynova 2121/3</p>
                <p>Quadrio Business Center</p>
                <p>Praga 11000, Republika Czeska</p>
                <p>
                  Telefon: <a href="tel:+4202819137389">+ 420 / 281913738-9</a>
                </p>
                <p>
                  E-mail:
                  <a href="mailto:tatprague@tat.or.th">tatprague@tat.or.th</a>
                </p>
              </div>
            </div>
            <div className="col-xl-6 offset-xl-1">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;
